import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  BranchDataProps,
  LeadManagerDataProps,
  LeadStatusDataProps,
  MigrateLeadsCriteriaFilters,
  SelectedLeadManager,
  SelectedLeadStatus,
} from '@/Types/LeadMigrateTypes'
import Lead = App.Models.Lead

export interface MigrateLeadSliceState {
  show_migrate_leads_drawer: boolean
  active_stepper: number
  is_filter_applied: boolean
  criteria_filters: MigrateLeadsCriteriaFilters | null
  selected_study_leads: Lead[]
  selected_lead_ids: string[]
  selected_lead_branches: number[]
  selected_lead_managers: SelectedLeadManager[]
  selected_lead_statuses: SelectedLeadStatus[]
  selected_branches_data: BranchDataProps[]
  selected_lead_managers_data: LeadManagerDataProps[]
  selected_lead_statuses_data: LeadStatusDataProps[]
  selected_leads_action: string | null
}

const initialState: MigrateLeadSliceState = {
  show_migrate_leads_drawer: false,
  active_stepper: 0,
  is_filter_applied: false,
  criteria_filters: {
    statuses: [],
    lead_source: '',
    sub_lead_source: '',
    lead_dates: [],
    resident_states: [],
    resident_cities: [],
    preferred_countries: [],
    branch_id: '',
    lead_managers: [],
  },
  selected_study_leads: [],
  selected_lead_ids: [],
  selected_lead_branches: [],
  selected_lead_managers: [],
  selected_lead_statuses: [],
  selected_branches_data: [],
  selected_lead_managers_data: [],
  selected_lead_statuses_data: [],
  selected_leads_action: null,
}

export const migrateLeadSlice = createSlice({
  name: 'migrateLeadSlice',
  initialState,
  reducers: {
    updateShowMigrateLeadsDrawer(state, action: PayloadAction<boolean>) {
      state.show_migrate_leads_drawer = action.payload
    },
    updateActiveStepper(state, action: PayloadAction<number>) {
      state.active_stepper = action.payload
    },
    updateCriteriaFilters(state, action: PayloadAction<MigrateLeadsCriteriaFilters | null>) {
      state.is_filter_applied = true
      state.criteria_filters = action.payload
    },
    updateSelectedStudyLeads(state, action: PayloadAction<Lead[]>) {
      state.selected_study_leads = action.payload
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_lead_ids = action.payload
    },
    updateSelectedLeadBranches(state, action: PayloadAction<number[]>) {
      state.selected_lead_branches = action.payload
    },
    updateSelectedLeadManagers(state, action: PayloadAction<SelectedLeadManager[]>) {
      state.selected_lead_managers = action.payload
    },
    updateSelectedLeadStatuses(state, action: PayloadAction<SelectedLeadStatus[]>) {
      state.selected_lead_statuses = action.payload
    },
    updateSelectedBranchData(state, action: PayloadAction<BranchDataProps[]>) {
      state.selected_branches_data = action.payload
    },
    updateSelectedLeadManagersData(state, action: PayloadAction<LeadManagerDataProps[]>) {
      state.selected_lead_managers_data = action.payload
    },
    updateSelectedLeadStatusesData(state, action: PayloadAction<LeadStatusDataProps[]>) {
      state.selected_lead_statuses_data = action.payload
    },
    updateSelectedLeadsAction(state, action: PayloadAction<string | null>) {
      state.selected_leads_action = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowMigrateLeadsDrawer,
  updateActiveStepper,
  updateSelectedStudyLeads,
  updateSelectedLeadIds,
  updateCriteriaFilters,
  updateSelectedLeadBranches,
  updateSelectedLeadManagers,
  updateSelectedLeadStatuses,
  updateSelectedBranchData,
  updateSelectedLeadManagersData,
  updateSelectedLeadStatusesData,
  updateSelectedLeadsAction,
} = migrateLeadSlice.actions

export default migrateLeadSlice.reducer
